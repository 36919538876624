@mixin border($size: 1px, $style: solid, $color: red) {
  border: $size $style $color;
}
@mixin font-medium(){
    font-family: 'Montserrat-medium';
}
@mixin font-light(){
    font-family: 'Montserrat-light';
}
@mixin font-bold(){
    font-family: 'Montserrat-bold';
}
@mixin font-weight($number: 700){
    font-weight: $number;
}
@mixin font-border($color, $size, $sizen){
    text-shadow: $sizen 0 $color, 0 $size $color, $size 0 $color, 0 $sizen $color;
}
