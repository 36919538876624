$white: #fff;
$black: #000;
$gray: #ccc;
$backG: rgb(231, 229, 231);
$main: #27509b;
$mDark: #153B80;
$mDarKes: #092A65;
$bLetter: #333333;
$bodyBackground: #f0f0f0;
$redLoti: #ff595e;
$errorRed: #f14545;
$yellowLoti: #e7b736;
