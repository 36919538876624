	/*
  	Flaticon icon font: Flaticon
  	Creation date: 08/02/2018 16:00
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-dice:before { content: "\f100"; }
.flaticon-plus-button:before { content: "\f101"; }
.flaticon-locked:before { content: "\f102"; }
.flaticon-email:before { content: "\f103"; }
.flaticon-pencil:before { content: "\f104"; }
.flaticon-arrows:before { content: "\f105"; }
.flaticon-phone-call:before { content: "\f106"; }
.flaticon-house:before { content: "\f107"; }
.flaticon-calendar:before { content: "\f108"; }
.flaticon-numeral-circular-button:before { content: "\f109"; }
.flaticon-numeral-symbol-in-a-circle:before { content: "\f10a"; }
.flaticon-hand-finger-pressing-a-circular-ring-button:before { content: "\f10b"; }
.flaticon-file:before { content: "\f10c"; }
.flaticon-sheet:before { content: "\f10d"; }
.flaticon-commerce:before { content: "\f10e"; }
.flaticon-profile:before { content: "\f10f"; }
.flaticon-can:before { content: "\f110"; }
